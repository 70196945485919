export const pageTexts = (t) => {
  return {
    topTitle: {
      title: {
        text: t("imprint.imprint"),
        colorClass: "colorDark",
        extraClass: "font40 text-center",
        heading: "h1",
      },
      separator: false,
    },
    foursourceGroup: [
      {
        text: t("imprint.foursourceGroup"),
        colorClass: "colorDark",
        textType: "subtitle",
        extraClass: "font24 pt-20 fontWeight600",
        heading: "h2",
      },
      {
        text: t("imprint.foursourceGroupText"),
        colorClass: "colorDark",
        extraClass: "font17 lineHeight1_7",
        textType: "paragraph",
      },
    ],
    companyRegistration: [
      {
        text: t("imprint.companyRegistration"),
        colorClass: "colorDark",
        textType: "subtitle",
        extraClass: "font24 pt-20 fontWeight600",
        heading: "h2",
      },
      {
        text: t("imprint.companyRegistrationText"),
        colorClass: "colorDark",
        extraClass: "font17 lineHeight1_7",
        textType: "paragraph",
      },
    ],
    disclaimer: [
      {
        text: t("imprint.disclaimer"),
        colorClass: "colorDark",
        textType: "subtitle",
        extraClass: "font24 pt-20 fontWeight600",
        heading: "h2",
      },
      {
        text: t("imprint.disclaimerText"),
        colorClass: "colorDark",
        extraClass: "font17 lineHeight1_7",
        textType: "paragraph",
      },
    ],
  };
};